<template>
  <div class="home-page" :style="{ backgroundImage: 'url(/white-background1.png)' }">
    <div class="content">
      <h1>Hello, I'm Ashhad</h1>
      <p class="tagline">Innovating at the intersection of Technology and Education</p>
      <div class="buttons">
        <button class="button main-button" @click="goToPortfolio">View My Portfolio</button>
        <button class="button main-button" @click="goToAbout">About Me</button>
        <button class="button secondary-button" @click="goToBlog">Blog</button>
        <button class="button secondary-button" @click="goToAchievements">Certifications</button>
        <button class="button secondary-button" @click="goToContact">Contact Me</button>
      </div>
    </div>
    <!-- Three.js canvas -->
    <!-- <div ref="threeCanvas" class="three-canvas"></div> -->
  </div>
</template>

<script>
// import * as THREE from 'three';
import { useRouter } from 'vue-router';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { markRaw } from 'vue';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      camera: null,
      renderer: null,
      scene: null,
    };
  },
  mounted() {
      window.addEventListener('resize', this.onWindowResize);
  },

  beforeUnmount() {
      window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    goToAbout() {
      this.router.push('/profile');
    },
    goToPortfolio() {
      this.router.push('/portfolio');
    },
    goToBlog() {
      this.router.push('/blog');
    },
    goToAchievements() {
      this.router.push('/achievements');
    },
    goToContact() {
      this.router.push('/contact');
    }

  }
}
</script>

<style scoped>

html, body {
   margin: 0;
   padding: 0;
   height: 100vh;  
   width: 100vw;   
   overflow: hidden;  
}


.home-page {
  position: relative;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; 
  z-index: 2; 
  text-align: center;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  
}


.button {
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform 0.3s;
  margin: 5px;
  border: none;
  border-radius: 5px;
}

.main-button {
  background-color: #007BFF;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.4);
}

.main-button:hover {
  transform: scale(1.1);
  color: #ccc;

  background-color: #007BFF;

  transition: color 0.3s;
  text-decoration: none;
}

.secondary-button {
  background-color: #6c757d;
  color: white; 
  
}

.secondary-button:hover {
  background-color: #5a6268;
  color: #ccc;
  transform: scale(1.1);
  transition: color 0.3s;
  text-decoration: none;
}

.three-canvas {
  position: absolute;  
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  z-index: 1;
}


</style>
